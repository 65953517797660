var startMap = function () {

    function StoresLocatorComponent() {
        var self = this;

        self.config = storesLocatorComponentConfig;

        self.filters = {
            expanded: ko.observable(false),
            text: ko.observable(),
            searchableText: '',
            distance: ko.observable(20),
            currentAestheticCategory: ko.observable(),
            currentProductCategory: ko.observable(),
            currentStoreType: ko.observable(),
            aestheticCategory: ko.observable(),
            productCategory: ko.observable(),
            storeType: ko.observable()
        };

        self.model = {
            stores: ko.observableArray()
                .paged(50, function (oldPage, newPage) {
                    self.dom.resetListScroll(oldPage, newPage);
                })
                .extend({deferred: true}),

            loadingCount: ko.observable(0),
            types: ko.observableArray([]),
            mandatoryTypes: [],
            productCategories: ko.observableArray([]),
            mandatoryProductCategories: [],
            aestheticCategories: ko.observableArray([]),
            mandatoryAestheticCategories: [],

            map: null,
            mapBounds: null,
            markers: [],
            markerClusters: null,
            fallbackLocation: self?.config?.map,
            fallbackBounds:null
        };

        // region Computed fields & subscriptions

        self.filtersSelection = ko.computed(function () {
            var labels = [];

            if (self.filters.storeType()) {
                labels.push(self.model.types().find(function (t) {
                    return t.key === self.filters.storeType();
                }).title);
            }

            if (self.filters.productCategory()) {
                labels.push(self.model.productCategories().find(function (c) {
                    return c === self.filters.productCategory();
                }));
            }

            if (self.filters.aestheticCategory()) {
                labels.push(self.model.aestheticCategories().find(function (c) {
                    return c === self.filters.aestheticCategory();
                }));
            }

            if (labels.length > 0) {
                return ' - ' + labels.join(", ");

            } else {
                return '';
            }
        });

        self.model.hasStores = ko.computed(function () {
            return self.model.stores().length > 0;
        });

        // endregion

        self.dom = {
            map: $('.map'),
            list: $('.stores-list'),

            resetListScroll: function (oldPage, newPage) {
                if (oldPage !== newPage) {
                    self.dom.list.stop(true, true).animate({scrollTop: 0}, 200);
                }
            },

            toggleFilters: function () {
                self.filters.expanded(!self.filters.expanded());
            },

            toggleStoreDetail: function (store) {
                if (!store.isExtended()) {
                    self.dom.bounceMarker(store.id);
                    self.model.stores().forEach(function (s) {
                        s.isExtended(false);
                    });
                }

                store.isExtended(!store.isExtended());
            },

            bounceMarker: function (storeId) {
                var marker = self.model.markers.find(function (m) {
                    return m.storeId === storeId;
                })
                if (marker) {
                    var clusters = self.model.markerClusters.clusters_; // use the get clusters method which returns an array of objects
                    var hiddenByCluster = false;

                    for (var i = 0, l = clusters.length; i < l; i++) {
                        if (clusters[i].clusterIcon_.visible_) {
                            for (var j = 0, le = clusters[i].markers_.length; j < le; j++) {
                                if (clusters[i].markers_[j].storeId === marker.storeId) {
                                    hiddenByCluster = true;
                                    break;
                                }
                            }
                        }
                    }

                    marker.setAnimation(google.maps.Animation.BOUNCE);
                    setTimeout(function () {
                        if (hiddenByCluster) {
                            marker.setMap(self.model.map);
                        }
                        marker.setAnimation(google.maps.Animation.BOUNCE);
                        setTimeout(function () {
                            marker.setAnimation(null);
                            if (hiddenByCluster) {
                                marker.setMap(null);
                            }
                        }, 3000);
                    }, 200);
                }
            }
        };

        self.actions = {
            loadUtilityData: function () {

                return Promise.all([
                    StoresLocatorUtils.api.getStoreTypes(self.config.apiUrls.storeTypes, self.config.allowedStoreTypes),
                    StoresLocatorUtils.api.getProductCategories(self.config.apiUrls.productCategories, self.config.allowedProductCategories),
                    StoresLocatorUtils.api.getAestheticCategories(self.config.apiUrls.aestheticCategories, self.config.allowedAestheticCategories)]
                ).then(function (values) {

                    values[0].forEach(function (v) {
                        if (self.config.allowedStoreTypes.indexOf(v.key) >= 0) {
                            self.model.mandatoryTypes.push(v.key);
                        }

                        self.model.types.push(v);
                    });

                    if (self.config.allowedStoreTypes.length === 1) {
                        var v = self.model.types()[0].key;
                        self.filters.currentStoreType(v);
                        self.filters.storeType(v);
                    }

                    values[1].forEach(function (v) {
                        if (self.config.allowedProductCategories.indexOf(v) >= 0) {
                            self.model.mandatoryProductCategories.push(v);
                        }

                        self.model.productCategories.push(v);
                    });

                    if (self.config.allowedProductCategories.length === 1) {
                        var v = self.model.productCategories()[0];
                        self.filters.currentProductCategory(v);
                        self.filters.productCategory(v);
                    }

                    values[2].forEach(function (v) {
                        if (self.config.allowedAestheticCategories.indexOf(v) >= 0) {
                            self.model.mandatoryAestheticCategories.push(v);
                        }

                        self.model.aestheticCategories.push(v);
                    });

                    if (self.config.allowedAestheticCategories.length === 1) {
                        var v = self.model.aestheticCategories()[0];
                        self.filters.currentAestheticCategory(v);
                        self.filters.aestheticCategory(v);
                    }

                    return true;

                }).catch(function (error) {
                    console.log(error);
                });
            },

            loadStores: async function () {
                let lat
                let lng
                let boundNorthEast
                let boundSouthWest

                let distStart
                let distEnd

                if(self.model.map !== null){

                    var center = self.model.map.getCenter();
                    var bounds = self.model.map.getBounds()
                    lat = center.lat()
                    lng = center.lng()
                    boundNorthEast = bounds.getNorthEast()
                    boundSouthWest = bounds.getSouthWest()

                    distStart = google.maps.geometry.spherical.computeDistanceBetween(center, boundNorthEast) / 1000;
                    distEnd = google.maps.geometry.spherical.computeDistanceBetween(center, boundSouthWest) / 1000;
                } else {

                    lat =  self.model.fallbackLocation.lat
                    lng =  self.model.fallbackLocation.lng

                    distStart = 20
                    distEnd = 20
                }

                var radius = cookieMapsEnabled ? ((distStart + distEnd) / 2) * 0.75 : 13000;

                StoresLocatorUtils.api.loadStores(
                    self.config.apiUrls.stores,
                    self.utils.buildFiltersRequest(lat, lng, radius)
                ).then(function (stores) {

                    self.model.stores.removeAll();
                    self.utils.clearClustering();
                    self.utils.clearMarkers();

                    if (stores && stores.length > 0) {


                            stores.forEach(function (store) {
                                store.isExtended = ko.observable(false);
                                if(self.model.map !== null) {
                                    var marker;
                                    if (store.latitude && store.longitude) {
                                        marker = new google.maps.Marker({
                                            icon: self.utils.findStoreTypeIcon(store.storeTypes),
                                            map: self.model.map,
                                            position: {
                                                lat: store.latitude,
                                                lng: store.longitude
                                            },
                                            storeId: store.id
                                        });

                                        marker.addListener('click', self.actions.focusOnStore);

                                        self.model.markers.push(marker);
                                    }
                                }
                            });
                            if(self.model.map !== null) {
                                self.utils.initClustering();
                            }


                        ko.utils.arrayPushAll(self.model.stores, stores);
                        self.model.stores.current(1);
                        self.dom.resetListScroll(0, 1);


                        if(self.model.loadingCount() > 0 ){
                            self.actions.sendGTM(stores)
                        }

                        self.model.loadingCount(self.model.loadingCount() + 1);
                    }
                });
            },

            textualSearch: function () {
                var text = self.filters.text();
                if (text) {
                    StoresLocatorUtils.map.getGeoPositionOfPlace(text, self.config.locale).then(function (result) {
                        if (result.length > 0) {
                            var g = result[0].geometry;
                            if (g.location_type === 'APPROXIMATE') {

                                self.filters.searchableText = '';

                                var loc = g.location;
                                self.model.fallbackLocation = g.location;
                                self.model.fallbackBounds = g.bounds;
                                if(self.model.map !== null ) {
                                    self.model.map.setCenter(loc);
                                    if (self.config.enableDistanceFiltering) {
                                        self.model.map.setZoom(StoresLocatorUtils.map.evaluateZoomByDistance(loc, self.dom.map.outerWidth(), self.filters.distance()));
                                    }
                                }

                                return;
                            }
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
                self.filters.searchableText = text;
                self.actions.loadStores().then(
                    window.dataLayer = window.dataLayer || [],
                    window.dataLayer.push({
                        event: 'applicazione-filtro-store-locator',
                        resultsNumber: self.model.stores().length,
                    }),
                )
            },

            sendGTM: function (stores) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'ricerca-store-vicino',
                    'city': self.filters.text() || '',
                    'distance': self.filters.distance() || ''
                });
            },

            search: function () {
                self.filters.storeType(self.filters.currentStoreType());
                self.filters.productCategory(self.filters.currentProductCategory());
                self.filters.aestheticCategory(self.filters.currentAestheticCategory());
                self.filters.expanded(false);

                self.actions.loadStores().then(
                    window.dataLayer = window.dataLayer || [],
                    window.dataLayer.push({
                        event: 'applicazione-filtro-store-locator',
                        resultsNumber: self.model.hasStores(),
                    }),
                )
            },

            zoomIn: function () {
                self.model.map.setZoom(self.model.map.zoom + 1);
            },

            zoomOut: function () {
                self.model.map.setZoom(self.model.map.zoom - 1);
            },

            focusOnStore: function () {

                var storeId = this.storeId;
                var page = 0;

                self.model.stores().forEach(function (store, index) {
                    if (store.id === storeId) {
                        store.isExtended(true);
                        page = index === 0 ? 1 : Math.ceil(index / self.model.stores.perPage);

                    } else {
                        store.isExtended(false);
                    }
                    store.isExtended(store.id === storeId);
                });

                self.model.stores.current(page);

                setTimeout(function () {
                    var $target = $('#store-' + storeId);
                    var $list = $('.stores-list');
                    $list.animate({scrollTop: $target.offset().top - ($list.offset().top - $list.scrollTop())}, 'slow');
                }, 500);
            }
        };

        self.utils = {
            cookieFirstCheck:function(){
                if(typeof CookieFirst !== 'undefined' ){
                    if(CookieFirst?.acceptedServices?.google_maps || CookieFirst?.consent?.advertising) {
                        return StoresLocatorUtils.map.initMap(self.dom.map, mapSettings);
                    }
                    else{
                        return null;
                    }
                }else{
                    return StoresLocatorUtils.map.initMap(self.dom.map, mapSettings);
                }
            },
            init: function (mapSettings) {
                Promise.all([
                    self.utils.cookieFirstCheck(),
                    self.actions.loadUtilityData()
                ]).then(function (values) {
                    if (values[0] && values[1]) {
                        self.model.map = values[0];
                        self.model.map.addListener('idle', self.actions.loadStores);

                        if (self.config.enableDistanceFiltering) {
                            self.filters.distance.subscribe(self.utils.resetMapOnDistance);
                        }

                        self.actions.loadStores();
                    }
                });
            },

            clearMarkers: function () {
                if (self.model.markers) {
                    self.model.markers.forEach(function (m) {
                        m.setMap(null);
                    });
                }

                self.model.markers.splice(0, self.model.markers.length);
            },

            clearClustering: function () {
                if (self.model.markerClusters) {
                    self.model.markerClusters.clearMarkers();
                }
            },

            initClustering: function () {

                var markers = self.model.markers.filter(function (m) {
                    return m.getMap() != null;
                });

                self.utils.clearClustering();

                // init clustering
                self.model.markerClusters = new MarkerClusterer(self.model.map, markers, {
                    styles: [
                        {
                            textColor: "#FFFFFF",
                            textSize: 12,
                            url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABmJLR0QA/wD/AP+gvaeTAAAC40lEQVRIibXWz0tjVxjG8efc20kWEu0Q7TSBgd6glKaIFEqGgVbswo7dtC4UN7PuH9BNF4JupJT2P3BhF6WCEheuBqdFCWLdiIqilHaVq/VHUdAiMjf3x7eLmQxp0KTTyX3h2b4fzjm8nNeoSQFG0oeSvpD0iaSMpLSkUNK5pLKkJUmLxpjfm/UzDSBL0mNJ34Zh+Pba2lqwvLx8p1wu6/T0VLZtK5vNKpfLaWhoyOvr60tK2pX0lTHml2ZwPZYHfru6uvInJyfp6upCUsPkcjmmp6ejIAgi4Geg879ij4DrxcXFKJPJNIXq09vby+bmpg8cAvlm2GdRFAVTU1NYlvXKWDVtbW3Mzc2FwN/Au7dhBcAbHx//31BtjDHMzs6GwBFwrx6zgT+KxWJgjGkJKIlUKsXOzk4FmK8Hv7y4uKh0dna2DKumUCgQRVEEPKxiFnA0MTHRcqyahYWFAHhSBT/yfT9Mp9OxgQMDAwABcFfA96VSqRIXJgnbtjk7O/OAx5akgZWVlTuKscIw1Orqqi3pgSUp67punJ4kyXVdW9I7lqT0yclJ7ODx8bEk3bckhYlEInbwheFZkv7KZDKxg9lsVpLKlqQDx3FiBx3HCSS5Ar7e399/phjHor29Hc/zAuCRgPcAuru7YwNHRkYAroGkJAnYmZmZieLALMtia2urAvzw8n6BT33fD/P5fMvBsbExgApw/1+PCqzs7e1VOjo6Wob19PRwfn7uAd+pvoB7wPH8/HzwOr99NalUiu3tbQ/4Fbh50IEPgOulpaXgdU7qOA67u7sV4AB460asBn0fKG9sbHiFQuGVseHhYQ4PDz1gDehqiNWgd4EfoygKi8ViZXBwkEQi0fD6RkdHWV9f94BnwBRw4w906yL8Au6V9I2kocvLS0qlknFd943aRdhxHL+/v99KJpMVST9JmjTGHN3WsyFYA78p6XNJH+v5qp/V81X/T0kHkp5KemqM8Zr1+gfw0vmpEoqYswAAAABJRU5ErkJggg==",
                            height: 27.5,
                            width: 27.5
                        }
                    ],
                    minimumClusterSize: 2,
                    averageCenter: true,
                    zoomOnClick: true
                });
            },

            resetMapOnDistance: function (distance) {
                var mapCenter = self.model.map.getCenter();
                var latLng = {lat: mapCenter.lat(), lng: mapCenter.lng()};
                var zoom = StoresLocatorUtils.map.evaluateZoomByDistance(latLng, self.dom.map.outerWidth(), distance);
                self.model.map.setZoom(zoom);
            },

            buildFiltersRequest: function (lat, lon, radius) {
                return {
                    textQuery: cookieMapsEnabled ? self.filters.searchableText : "",
                    storeType: self.filters.storeType() ? self.filters.storeType() : self.model.mandatoryTypes.join(","),
                    productCategory: self.filters.productCategory() ? self.filters.productCategory() : self.model.mandatoryProductCategories.join(","),
                    aestheticCategory: self.filters.aestheticCategory() ? self.filters.aestheticCategory() : self.model.mandatoryAestheticCategories.join(","),
                    latitude: lat,
                    longitude: lon,
                    radius: radius
                };
            },

            getStoreTypeIcon: function (storeId) {
                var icon = '';

                self.model.types().forEach(function (t) {
                    if (t.key === storeId) {
                        icon = t.iconUrl;
                    }
                });

                return icon;
            },

            findStoreTypeIcon: function (storeTypes) {
                let result = '';
                if(storeTypes && storeTypes.length > 0){
                    storeTypes.forEach(function (storeType){
                        let icon = self.utils.getStoreTypeIcon(storeType);
                        if(icon && icon !== ''){
                            result = icon;
                        }
                    });
                }
                return result;
            }
        };
    }

    if(typeof CookieFirst !== 'undefined' && (CookieFirst?.acceptedServices?.google_maps || CookieFirst?.consent?.advertising)){
        var slComponent = new StoresLocatorComponent();
        var $storeLocatorComponent = $('.stores-locator__component')[0];
        if (!ko.dataFor($storeLocatorComponent)){
            ko.applyBindings(slComponent, $storeLocatorComponent);
        }
    }else{
        return;
    }

    StoresLocatorUtils.map.metricSystem = slComponent.config.metricSystem;
    var mapSettings = StoresLocatorUtils.map.getStoresMapSettings(slComponent.config);

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            function (p) {
                mapSettings.center.lat = p.coords.latitude;
                mapSettings.center.lng = p.coords.longitude;
                slComponent.config.map.lat = p.coords.latitude;
                slComponent.config.map.lng = p.coords.longitude;
                slComponent.utils.init(mapSettings);
            },
            function () {
                slComponent.utils.init(mapSettings);
            },
            {
                enableHighAccuracy: true,
                timeout: 1000
            });

    } else {
        slComponent.utils.init(mapSettings);
    }
};
